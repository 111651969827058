import { Injectable, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    message = signal<string>('Usuário autenticado com sucesso.');
    show = signal<boolean>(false);
    type = signal<string>('success');

    setToast(show: boolean, message: string, type: string, persist = ''): void {
        if (show !== this.show()) {
            this.show.set(show);
        }
        if (message !== this.message()) {
            this.message.set(message);
        }
        if (type !== this.type()) {
            this.type.set(type);
        }

        if (persist !== 'persist') {
            const id = setTimeout(() => {
                this.show.set(false);

                clearTimeout(id);
            }, 4000);
        }
    }
}
